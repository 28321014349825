
























import { DropzoneFile } from 'dropzone'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import NotifyMixin from '@/mixins/NotifyMixin'
import { baseURL, getToken } from '@/utils/services/config'
import { NameValueResource } from '@/store/types'
import SystemModule from '@/store/modules/system'

@Component({
  components: {
    'vue-dropzone': VueDropzone,
  },
  inheritAttrs: false,
})
export default class UploadInput extends Mixins(NotifyMixin) {
  @Prop({ default: 'dropzone' })
  private id!: string

  @Prop({ default: 'image/*' })
  private accept!: string

  @Prop({ default: 'Загрузить файл' })
  private placeholder!: string

  @Prop({ default: false })
  private disabled!: boolean

  // Mb
  @Prop({ default: 1200 })
  private maxSize!: number

  @Prop({ default: true })
  private chunk!: boolean

  @Prop({ default: () => ([]) })
  private additionalParams!: NameValueResource[]

  @Prop({ default: false })
  private isTraining!: boolean

  @Prop({ default: false })
  private iconActionBtn!: boolean

  // Нужен для определения прогресса для почанковой загрузки
  private totalFileSize = 0

  private get isMaster(): boolean {
    return SystemModule.interface === 'master'
  }

  private get headers () {
    const headers: HeadersInit = {}

    const authToken = getToken()

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    return headers
  }

  private get options () {
    return {
      acceptedFiles: this.accept,
      addRemoveLinks: true,
      autoProcessQueue: true,
      autoQueue: true,
      chunkSize: this.isMaster ? 800000 : 8000000,
      chunking: this.chunk,
      chunksUploaded: this.handleUploaded,
      createImageThumbnails: true,
      forceChunking: this.chunk,
      headers: this.headers,
      maxFilesize: 0,
      // maxFiles: this.max, //Mb
      thumbnailHeight: 24,
      thumbnailWidth: 24,
      url: this.url,
      withCredentials: true,
    }
  }

  private get url() {
    if (this.isTraining) {
      return this.chunk ? `${baseURL}/trainer/upload/chunk` : `${baseURL}/trainer/upload`
    }
    return this.chunk ? `${baseURL}/upload/chunk` : `${baseURL}/upload`
  }

  private handleSending(file: DropzoneFile, xhr: any, formData: FormData) {
    if (this.chunk) {
      this.additionalParams.forEach(item => {
        if (item.name && item.value) {
          formData.append(item.name, item.value.toString())
        }
      })

      formData.append('filename', file.name)
      formData.append('uuid', formData.get('dzuuid') || '')
      formData.append('chunk', formData.get('dzchunkindex') || '')
      formData.append('chunks', formData.get('dztotalchunkcount') || '')
      formData.append('size', file.size.toString())
      this.totalFileSize = file.size
    }
  }

  private handleUploaded (file: DropzoneFile, done: any) {
    if (file.xhr && file.xhr.response) {
      const json = JSON.parse(file.xhr.response)
      this.$emit('success', json.result)
    }

    (this.$refs.dz as any).removeFile(file)

    done()
  }

  private handleUploadProgress (percent: number, totalBytes: number, totalBytesSent: number) {
    const uploadEl: HTMLDivElement | null = ((this.$refs.dz as any).$el as HTMLDivElement).querySelector('.dz-upload')
    const progress = totalBytesSent * 100 / this.totalFileSize
    const showProgress = progress < 1 ? +progress.toFixed(2) : Math.round(progress)
    if (uploadEl) {
      uploadEl.style.width = `${showProgress}%`
    }
    this.$emit('progress', showProgress)
  }

  private handleComplete (file: DropzoneFile) {
    if (this.chunk) {
      return true
    }

    if (file.xhr) {
      const json = JSON.parse(file.xhr.response)
      this.$emit('success', json)
    }

    (this.$refs.dz as any).removeFile(file)
  }

  private handleError (file: DropzoneFile, message: any, xhr: any) {
    (this.$refs.dz as any).removeFile(file)

    if (file.status !== 'canceled')
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.notifyError(`Размер файла не должен превышать ${this.maxSize}Mb`)
      } else if (!file.accepted) {
        this.notifyError(`Ошибка загрузки. Проверьте формат загружаемого файла (${this.accept})`)
      } else if (xhr) {
        this.notifyError(`Во время загрузки файла произошла ошибка (${xhr.statusText || message}). Повторите попытку позднее.`)
      } else {
        this.notifyError('Что-то пошло не так. Повторите попытку позднее или перезагрузите страницу.')
      }
  }

  // Используется для открытия выбора файла извне компонента. Через this.$refs.upload.openPicker()
  public openPicker () {
    (this.$refs.dz as any).$el.click()
  }
}
