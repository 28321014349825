import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Cookies from 'js-cookie'

import store from '@/store'
import {
  ConstantGetRequest,
  DictionariesCitiesGetRequest,
  DictionariesGetRequest,
  DictionariesTimezonesGetRequest,
  EducationMasterGroupResource,
  ExerciseStatus,
  FilterLessonStatus,
  ManagerCoursesTreeSelectGetParams,
  ManagerCoursesTreeSelectGetRequest,
  ManagerMasterGroupsMasterGroupIdMonthsSelectGetRequest,
  ManagerMasterGroupsSelectGetParams,
  ManagerMasterGroupsSelectGetRequest,
  ManagerMastersMasterIdMasterGroupsSelectGetRequest,
  ManagerMastersSelectGetParams,
  ManagerMastersSelectGetRequest,
  ManagerMentorsSelectGetRequest,
  ManagerRolesDictionaryGetRequest,
  ManagerUsersSelectGetRequest,
  ManagerSalesSelectGetRequest,
  NameValueResource,
  RoleType,
  CountersResource,
  ManagerCountersGetRequest,
  DictionaryResource,
  DictionariesSaleStatusesGetRequest,
} from '@/store/types'
import { getStorage, parseDateToMilliseconds, setStorage } from '@/utils/functions'

/**
 * Работа со словарями системы, а также получение списков для селектов
 */

@Module({
  dynamic: true,
  name: 'Dictionary',
  namespaced: true,
  store,
})
class Dictionary extends VuexModule {
  // ---------------------------- Города ---------------------------- >>
  cities: NameValueResource[] = getStorage('cities') || []

  @Mutation
  setCities (payload: NameValueResource[]) {
    this.cities = [...payload]
  }

  @Action({ rawError: true })
  async fetchCities () {
    if (!this.cities.length || !getStorage('cities')) {
      const { data } = await DictionariesCitiesGetRequest()
      this.setCities(data)
      setStorage('cities', data, 365)
      return data
    }
    return this.cities
  }

  // ---------------------------- Таймзоны ---------------------------- >>
  timezones: NameValueResource[] = getStorage('timezones') || []

  @Mutation
  setTimezones (payload: NameValueResource[]) {
    this.timezones = [...payload]
  }

  @Action({ rawError: true })
  async fetchTimezones () {
    if (!this.timezones.length || !getStorage('timezones')) {
      const { data } = await DictionariesTimezonesGetRequest()
      this.setTimezones(data)
      setStorage('timezones', data, 365)
      return data
    }
    return this.timezones
  }

  // ---------------------------- Скидки ---------------------------- >>
  discounts: NameValueResource[] = []

  @Mutation
  setDiscounts (payload: NameValueResource[]) {
    this.discounts = [...payload]
  }

  @Action({ rawError: true })
  async fetchDiscounts () {
    if (!this.discounts.length) {
      const { data } = await ManagerSalesSelectGetRequest()
      this.setDiscounts(data)
      return data
    }
  }

  // ---------------------------- Статусы скидок ---------------------------- >>
  discountsStatuses: NameValueResource[] = []

  @Mutation
  setDiscountsStatuses(payload: NameValueResource[]) {
    this.discountsStatuses = [...payload]
  }

  @Action({ rawError: true })
  async fetchDiscountsStatuses() {
    if (!this.discountsStatuses.length) {
      const { data } = await DictionariesSaleStatusesGetRequest()
      this.setDiscountsStatuses(data)
      return data
    }
  }

  // ---------------------------- Группы таймзон ---------------------------- >>
  timezoneGroups: NameValueResource[] = []

  @Mutation
  setTimezoneGroups (payload: NameValueResource[]) {
    this.timezoneGroups = [...payload]
  }

  // ---------------------------- Предметы ---------------------------- >>
  subjects: NameValueResource[] = []

  @Mutation
  setSubjects (payload: NameValueResource[]) {
    this.subjects = [...payload]
  }

  // ---------------------------- Массив созданных в системе ролей ---------------------------- >>
  roles: NameValueResource[] = []

  @Mutation
  setRoles (payload: NameValueResource[]) {
    this.roles = [...payload]
  }

  @Action({ rawError: true })
  async fetchRoles () {
    if (!this.roles.length) {
      const { data } = await ManagerRolesDictionaryGetRequest()
      this.setRoles(data)
      return data
    }
    return this.roles
  }

  // ---------------------------- Цвета ---------------------------- >>
  colors: NameValueResource[] = []

  @Mutation
  setColors (payload: NameValueResource[]) {
    this.colors = [...payload]
  }

  // ---------------------------- Типы полезных материалов ---------------------------- >>
  usefulMaterialsTypes: NameValueResource[] = []

  @Mutation
  setUsefulMaterialsTypes(payload: NameValueResource[]) {
    this.usefulMaterialsTypes = [...payload]
  }

  // ---------------------------- Типы групп курсов ---------------------------- >>
  groupTypes: NameValueResource[] = []

  @Mutation
  setGroupTypes (payload: NameValueResource[]) {
    this.groupTypes = [...payload]
  }

  // ---------------------------- Типы пакетов ---------------------------- >>
  packageTypes: NameValueResource[] = []

  @Mutation
  setPackageTypes (payload: NameValueResource[]) {
    this.packageTypes = [...payload]
  }

  // ---------------------------- Типы ДЗ по количеству заданий ---------------------------- >>
  exerciseTypes: NameValueResource[] = []

  @Mutation
  setExerciseTypes(payload: NameValueResource[]) {
    this.exerciseTypes = [...payload]
  }

  // ---------------------------- Типы доступа до домашних заданий ---------------------------- >>
  exerciseAccessLevels: NameValueResource[] = []

  @Mutation
  setExerciseAccessLevels (payload: NameValueResource[]) {
    this.exerciseAccessLevels = [...payload]
  }

  // ---------------------------- Типы ответов теста домашнего задания ---------------------------- >>
  taskRateTypes: NameValueResource[] = []

  @Mutation
  setTaskRateTypes (payload: NameValueResource[]) {
    this.taskRateTypes = [...payload]
  }

  // ---------------------------- Типы вопросов теста домашнего задания ---------------------------- >>
  taskQuestionTypes: NameValueResource[] = []

  @Mutation
  setTaskQuestionTypes (payload: NameValueResource[]) {
    this.taskQuestionTypes = [...payload]
  }

  // ---------------------------- Типы вопросов устной части ---------------------------- >>
  taskSpeakingQuestionTypes: NameValueResource[] = []

  @Mutation
  setTaskSpeakingQuestionTypes(payload: NameValueResource[]) {
    this.taskSpeakingQuestionTypes = [...payload]
  }

  // ---------------------------- Типы домашних заданий ---------------------------- >>
  taskTypes: NameValueResource[] = []

  @Mutation
  setTaskTypes (payload: NameValueResource[]) {
    this.taskTypes = [...payload]
  }

  // ---------------------------- Типы домашних заданий ---------------------------- >>
  ticketStatuses: NameValueResource[] = []

  @Mutation
  setTicketStatuses (payload: NameValueResource[]) {
    this.ticketStatuses = [...payload]
  }

  // ---------------------------- Типы курсов ---------------------------- >>
  courseTypes: NameValueResource[] = []

  @Mutation
  setCourseTypes (payload: NameValueResource[]) {
    this.courseTypes = [...payload]
  }

  // ---------------------------- Статусы заказов ---------------------------- >>
  orderStatuses: NameValueResource[] = []

  @Mutation
  setOrderStatuses (payload: NameValueResource[]) {
    this.orderStatuses = [...payload]
  }

  // ---------------------------- Типы курсов ---------------------------- >>
  promotionCodeTypes: NameValueResource[] = []

  @Mutation
  setPromotionCodeTypes (payload: NameValueResource[]) {
    this.promotionCodeTypes = [...payload]
  }

  // ---------------------------- Типы скидок ---------------------------- >>
  promotionDiscountTypes: NameValueResource[] = []

  @Mutation
  setPromotionDiscountTypes (payload: NameValueResource[]) {
    this.promotionDiscountTypes = [...payload]
  }

  // ---------------------------- Типы скидок ---------------------------- >>
  promotionProductTypes: NameValueResource[] = []

  @Mutation
  setPromotionProductTypes (payload: NameValueResource[]) {
    this.promotionProductTypes = [...payload]
  }

  // ---------------------------- Типы поиска ---------------------------- >>
  searchTypes: NameValueResource[] = []

  @Mutation
  setSearchType (payload: NameValueResource[]) {
    this.searchTypes = [...payload]
  }

  // ---------------------------- Тип операторов для поиска ---------------------------- >>
  searchOperators: NameValueResource[] = []

  @Mutation
  setSearchOperators (payload: NameValueResource[]) {
    this.searchOperators = [...payload]
  }

  // ---------------------------- Статус аккаунта пользователей ---------------------------- >>
  userStatuses: NameValueResource[] = []

  @Mutation
  setUserStatuses (payload: NameValueResource[]) {
    this.userStatuses = [...payload]
  }

  // ---------------------------- Статусы заданий ---------------------------- >>
  exerciseStatuses: NameValueResource[] = []

  @Mutation
  setExerciseStatuses (payload: NameValueResource[]) {
    this.exerciseStatuses = [...payload]
  }

  // ---------------------------- Ошибки заказа ---------------------------- >>
  paymentErrors: NameValueResource[] = []

  @Mutation
  setPaymentErrors(payload: NameValueResource[]) {
    this.paymentErrors = [...payload]
  }

  // ---------------------------- Статусы заданий ---------------------------- >>
  cashboxes: NameValueResource[] = []

  @Mutation
  setCashboxes (payload: NameValueResource[]) {
    this.cashboxes = [...payload]
  }

  // Для селектов
  exerciseStatusesFilter: NameValueResource[] = [
    {
      name: 'К проверке',
      value: ExerciseStatus.ON_CHECK,
    },
    {
      name: 'Не сдано',
      value: ExerciseStatus.WAIT,
    },
    {
      name: 'Завершено',
      value: ExerciseStatus.COMPLETE,
    },
  ]

  // ---------------------------- Статусы уроков ---------------------------- >>
  // Для селектов
  lessonStatusesFilter: NameValueResource[] = [
    {
      name: 'Все',
      value: FilterLessonStatus.ALL,
    },
    {
      name: 'Просмотренные',
      value: FilterLessonStatus.CHECKED,
    },
    {
      name: 'Непросмотренные',
      value: FilterLessonStatus.NOT_CHECKED,
    },
  ]

  // ---------------------------- Типы объектов скидок ---------------------------- >>
  // Для селектов
  saleUnitType: NameValueResource[] = []

  @Mutation
  setSaleUnitType(payload: NameValueResource[]) {
    this.saleUnitType = [...payload]
  }

  // ---------------------------- Общий запрос на получение словарей ---------------------------- >>
  @Action({ rawError: true })
  async fetchDictionaries () {
    let localData = getStorage<DictionaryResource>('dictionaries') || null

    if (!localData) {
      const { data } = await DictionariesGetRequest()
      setStorage('dictionaries', data, 365)
      localData = data
    }

    if (localData.colors) {
      this.setColors(localData.colors)
    }
    if (localData.usefulMaterialTypes) {
      this.setUsefulMaterialsTypes(localData.usefulMaterialTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setExerciseAccessLevels(localData.exerciseAccessLevels)
    }
    if (localData.exerciseAccessLevels) {
      this.setCourseTypes(localData.courseTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setGroupTypes(localData.groupTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setSubjects(localData.subjects)
    }
    if (localData.exerciseAccessLevels) {
      this.setTaskTypes(localData.taskTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setTaskQuestionTypes(localData.taskQuestionTypes)
    }
    if (localData.taskSpeakingQuestionTypes) {
      this.setTaskSpeakingQuestionTypes(localData.taskSpeakingQuestionTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setTaskRateTypes(localData.taskRateTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setTicketStatuses(localData.ticketStatuses)
    }
    if (localData.exerciseAccessLevels) {
      this.setOrderStatuses(localData.orderStatuses)
    }
    if (localData.exerciseAccessLevels) {
      this.setPromotionCodeTypes(localData.promotionCodeTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setPromotionDiscountTypes(localData.promotionDiscountTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setPromotionProductTypes(localData.promotionProductTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setSearchType(localData.searchTypes)
    }
    if (localData.exerciseAccessLevels) {
      this.setSearchOperators(localData.searchOperators)
    }
    if (localData.exerciseAccessLevels) {
      this.setTimezoneGroups(localData.timezoneGroups)
    }
    if (localData.exerciseAccessLevels) {
      this.setUserStatuses(localData.userStatuses)
    }
    if (localData.exerciseAccessLevels) {
      this.setExerciseStatuses(localData.exerciseStatuses)
    }
    if (localData.exerciseAccessLevels) {
      this.setCashboxes(localData.cashboxes)
    }
    if (localData.saleUnitTypes) {
      this.setSaleUnitType(localData.saleUnitTypes)
    }
    if (localData.paymentErrors) {
      this.setPaymentErrors(localData.paymentErrors)
    }
    if (localData.saleStatuses) {
      this.setDiscountsStatuses(localData.saleStatuses)
    }
    if (localData.packageTypes) {
      this.setPackageTypes(localData.packageTypes)
    }
    if (localData.exerciseTypeFilters) {
      this.setExerciseTypes(localData.exerciseTypeFilters)
    }
    return localData
  }

  // ---------------------------- Константы  ---------------------------- >>
  // from 1970
  currentTime: number = Date.now()
  currentTimeRaw: string = new Date(this.currentTime).toISOString()

  @Mutation
  setCurrentTime (payload: number) {
    this.currentTime = payload
  }

  @Mutation
  setCurrentTimeRaw (payload: string) {
    this.currentTimeRaw = payload
  }

  @Action({ rawError: true })
  async fetchConstants () {
    const { data } = await ConstantGetRequest()

    this.setCurrentTime(parseDateToMilliseconds(data.currentDateTime))
    this.setCurrentTimeRaw(data.currentDateTime)

    return data
  }

  // ---------------------------- Запросы для селектов (в том числе и для поиска) ---------------------------- >>
  @Action({ rawError: true })
  async searchCoursesTree (payload?: ManagerCoursesTreeSelectGetParams) {
    const { data } = await ManagerCoursesTreeSelectGetRequest(
      payload,
      { loading: false },
    )
    return data
  }

  masterGroupsSelect: EducationMasterGroupResource[] = []

  @Mutation
  setMasterGroups (payload: EducationMasterGroupResource[]) {
    this.masterGroupsSelect = payload
  }

  @Action({ rawError: true })
  async searchMasterGroups (payload?: ManagerMasterGroupsSelectGetParams) {
    const { data } = await ManagerMasterGroupsSelectGetRequest(
      payload,
      { loading: false },
    )
    this.setMasterGroups(data)
    return data
  }

  @Action({ rawError: true })
  async searchMonthsByMasterGroup (masterGroupId: number) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMonthsSelectGetRequest(
      masterGroupId,
      { loading: false },
    )
    return data
  }

  @Action({ rawError: true })
  async searchUsers (payload: { query: string, roleTypes?: RoleType[] }) {
    const { data } = await ManagerUsersSelectGetRequest(
      {
        query: payload.query,
        roleTypes: payload.roleTypes,
      },
      { loading: false },
    )
    return data
  }

  @Action({ rawError: true })
  async searchMasters (payload: ManagerMastersSelectGetParams) {
    const { data } = await ManagerMastersSelectGetRequest(
      payload,
      { loading: false },
    )
    return data
  }

  @Action({ rawError: true })
  async searchMentors () {
    const { data } = await ManagerMentorsSelectGetRequest(undefined, { loading: false })
    return data
  }

  // ---------------------------- Мастер-группы мастера по id ---------------------------- >>
  masterGroupsByUserId: EducationMasterGroupResource[] = []

  @Mutation
  setMasterGroupByUserId (payload: EducationMasterGroupResource[]) {
    this.masterGroupsByUserId = payload
  }

  @Action({ rawError: true })
  async fetchMasterGroupByMasterId (masterId: number) {
    const { data } = await ManagerMastersMasterIdMasterGroupsSelectGetRequest(masterId)
    this.setMasterGroupByUserId(data)
    return data
  }

  // ------------- Счётчик непрочитанных сообщений менеджера в техподдержке ------------- >>
  messageCounter = Cookies.get('messageCounter') || 0

  @Mutation
  setMessageCounter(payload: CountersResource) {
    this.messageCounter = payload.tickets || 0
    Cookies.set('messageCounter', payload.tickets, { expires: 3 / 1440 })
  }

  @Action({ rawError: true })
  async fetchMessageCount() {
    const { data } = await ManagerCountersGetRequest({ loading: false })
    this.setMessageCounter(data)
    return data
  }
}

const DictionaryModule = getModule(Dictionary)

export default DictionaryModule
