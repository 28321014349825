import { render, staticRenderFns } from "./SidenavMaster.vue?vue&type=template&id=14d22280&scoped=true&lang=pug&"
import script from "./SidenavMaster.vue?vue&type=script&lang=ts&"
export * from "./SidenavMaster.vue?vue&type=script&lang=ts&"
import style0 from "./SidenavMaster.vue?vue&type=style&index=0&id=14d22280&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d22280",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBadge,VList,VListItem,VNavigationDrawer})
