





































import { Component, Prop, Vue } from 'vue-property-decorator'

import { UserShortResource } from '@/store/types'
import Hearts from '@/components/Hearts.vue'
import Tag from '@/components/_uikit/Tag.vue'
import { countDayInMonth, formatDate } from '@/utils/functions'

@Component({
  components: {
    Hearts,
    Tag,
  },
})
export default class UserCard extends Vue {
  @Prop({ required: true })
  private user!: UserShortResource

  @Prop({ default: 'Без пользователя' })
  private emptyUserMessage!: string

  @Prop({ default: false })
  private onlyName!: boolean

  @Prop({ default: false })
  private showPartyHat!: boolean

  @Prop({ default: false })
  private large!: boolean

  @Prop({ default: '' })
  private hint!: string

  @Prop({ default: null })
  private hearts!: number

  @Prop({ default: false })
  private tealHint!: boolean

  @Prop({ default: false })
  private tgIcon!: boolean

  @Prop({ default: false })
  private isHeader!: boolean

  @Prop({ default: '' })
  private lastBoughtMonthDate!: string

  private get username () {
    if (!this.user) {
      return this.emptyUserMessage
    } else if (this.onlyName) {
      return this.user.name
    } else {
      return this.user.surname ? `${this.user.name} ${this.user.surname}` : this.user.name
    }
  }

  private get socialVK() {
    return this.user.socials?.find(item => item.type === 'vkontakte')
  }

  private get socialTG() {
    return this.user.socials?.find(item => item.type === 'telegram')
  }

  private get countDay() {
    if (this.lastBoughtMonthDate)
      return countDayInMonth(this.lastBoughtMonthDate)
    return 0
  }

  private get month() {
    if (this.lastBoughtMonthDate)
      return formatDate(this.lastBoughtMonthDate, 'MMM yyyy')
    return ''
  }
}
