






































































import { Component, Prop } from 'vue-property-decorator'
import camelCaseKeys from 'camelcase-keys'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import AuthModule from '@/store/modules/auth'
import { isShowFoolsDay } from '@/utils/constants'

@Component
export default class SidenavMaster extends DetectSafariMixin {
  @Prop({ default: false })
  private isHidden!: boolean

  @Prop({ default: false })
  private isMobile!: boolean

  private get unreadTicketsCount() {
    return AuthModule.unreadTicketsCount
  }

  private get userID() {
    return AuthModule.self?.id
  }

  private get isShowFoolsDay() {
    return isShowFoolsDay
  }

  private visible = false

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
    vuescroll: {
      sizeStrategy: 'number',
    },
  }

  private sub: any = null

  private mounted () {
    this.visible = !this.isHidden
    this.$bus.$on('sidenav-toggle', this.handleToggle)

    this.sub = this.$centrifuge.newSubscription(`support.message.${this.userID}`)
    this.sub.on('publication', (ctx: any) => {
      const data = camelCaseKeys(ctx.data, { deep: true })
      AuthModule.setUnreadTicketsCount(data.newMessagesCount)
      this.$bus.$emit('updateTicketsList')
    })
    this.sub.subscribe()
  }

  private destroyed() {
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }
  }

  private handleToggle (value: boolean) {
    this.visible = value
  }

  private handleChangeVisible (value: boolean) {
    this.$bus.$emit('sidenav-toggle', value)
  }
}
