import auth from '@/router/_middleware/auth'
import manageTraining from '@/router/_middleware/manageTraining'

export default [
  {
    component: () => import('@/views/manager/training/New.vue'),
    meta: { middleware: [auth, manageTraining] },
    name: 'manager.training.new',
    path: '/manager/training/new',
  },
  {
    component: () => import('@/views/manager/training/Item.vue'),
    meta: { middleware: [auth, manageTraining] },
    name: 'manager.training.item',
    path: '/manager/training/:questionID',
  },
  {
    component: () => import('@/views/manager/training/List.vue'),
    meta: { middleware: [auth ,manageTraining] },
    name: 'manager.training',
    path: '/manager/training',
  },
]
