import { requestTraining as request } from '@/utils/services/client'
/**
 * Этот файл сгенерирован автоматически. Не вносите изменения вручную.
 */

export interface FavoriteRequest {
  isFavorite: boolean,
}

export interface AnsweredQuestionResource {
  id: number,
  question: string,
  rightAnswer: string|null,
  type: QuestionType,
  media: MediaResource[],
  answerMedia: MediaResource[],
  answers: AnswerResource[],
  sequences: SequenceResource[],
  nameAnswerTable: string|null,
  nameSequenceTable: string|null,
  displayType: string|null,
  uuid: string,
  relatedUuid: string|null,
  kimLine: number,
  theme: NameValueResource,
  section: NameValueResource,
  source: NameValueResource,
  isPublished: NameValueResource,
  related: SingleQuestionResource[],
  subject: NameValueResource,
  userAnswers: UserAnswerEntityResource,
}

export interface DecideRequestId {
  isPublished: boolean,
}

export interface DecideRequest {
  id: DecideRequestId[],
}

export interface TrainerPaginator {
  meta: PaginatorMeta,
  data: TrainerPaginatorResource[],
}

export interface TrainerPaginatorResource {
  id: number,
  question: string,
  rightAnswer: string|null,
  media: MediaResource[],
  answerMedia: MediaResource[],
  answers: AnswerResource[],
  sequences: SequenceResource[],
  nameAnswerTable: string|null,
  nameSequenceTable: string|null,
  displayType: string|null,
  uuid: string,
  relatedUuid: string|null,
  kimLine: number,
  theme: NameValueResource,
  section: NameValueResource,
  source: NameValueResource,
  userAnswers: UserAnswerResource[],
  type: QuestionType,
}

export interface UserAnswerResource {
  answerId: number,
  answer: string,
  sequenceId: number,
  position: number,
  questionId: number,
}

export interface UserAnswerEntityResource {
  answerId?: number,
  answer?: string,
  sequenceId?: number,
  position?: number,
  questionId?: number,
}

export interface UploadRequest {
  file: Blob,
}

export interface MediaResource {
  id: number,
  type: string,
  filename: string,
  url: string,
  size: number,
}

export interface ChunkRequest {
  file: Blob,
  uuid: string,
  size: number,
  chunks: number,
  chunk: number,
  filename: string,
  folder?: string,
}

export interface MediaChunkResource {
  result?: MediaResource,
}

export interface SubjectResource {
  id: number,
  alias: Subjects,
  title: string,
}

export interface SectionRequest {
  subjectId: number,
  title?: string|null,
}

export interface SectionResource {
  id: number,
  subjectId: number,
  title: string,
}

export interface SourceResource {
  id: number,
  subjectId: number,
  title: string,
}

export interface ThemeRequest {
  sectionId: number,
  title?: string|null,
}

export interface ThemeResource {
  id: number,
  sectionId: number,
  title: string,
}

export interface QuestionStoreRequest {
  id?: number,
  uuid: string,
  type: QuestionType,
  question: string,
  mediaIds: number[],
  answers: QuestionAnswerPart[],
  sequences: QuestionSequencePart[],
  nameAnswerTable?: string|null,
  nameSequenceTable?: string|null,
  displayType?: string|null,
  relatedUuid?: string|null,
  rightAnswer: string|null,
  answerMediaIds?: number[],
  kimLine: number,
  themeId: number,
  sectionId: number,
  sourceId: number,
  subjectId: number,
  related: QuestionStoreRequest[],
  subject: Subjects,
}

export interface NameValueResource {
  name: string,
  value: number|string,
}

export interface AnswerResource {
  id: number,
  text: string,
  position: number,
  rightPosition?: number|null,
  isCorrect: boolean|null,
  sequencePosition?: number|null,
}

export interface SequenceResource {
  id: number,
  text: string,
  position: number,
}

export enum QuestionType {
  RATIO = 'ratio',
  TEXT = 'text',
  SEQUENCE = 'sequence',
  MULTIPLE = 'multiple',
  SINGLE = 'single',
  EMPTY = 'empty',
  NO_ANSWER = 'no_answer',
}

export enum Subjects {
  HISTORY = 'history',
  SOCIAL = 'social',
  LITERATURE = 'literature',
  RUSSIAN_LANGUAGE = 'russian-language',
  BIOLOGY = 'biology',
  ENGLISH_LANGUAGE = 'english-language',
  CHEMISTRY = 'chemistry',
  MATHEMATICS = 'mathematics',
  PHYSICS = 'physics',
  MATHEMATICS_BASE = 'mathematics_base',
  INFORMATICS = 'informatics',
}

export interface QuestionAnswerPart {
  id?: number,
  text: string,
  position: number,
  isCorrect: boolean,
  sequencePosition?: number,
}

export interface QuestionSequencePart {
  id?: number,
  text: string,
  position: number,
}

export interface SingleQuestionResource {
  id: number,
  question: string,
  rightAnswer: string|null,
  type: QuestionType,
  media: MediaResource[],
  answerMedia: MediaResource[],
  answers: AnswerResource[],
  sequences: SequenceResource[],
  nameAnswerTable: string|null,
  nameSequenceTable: string|null,
  displayType: string|null,
  uuid: string,
  relatedUuid: string|null,
  kimLine: number,
  theme: NameValueResource,
  section: NameValueResource,
  source: NameValueResource,
  isPublished: NameValueResource,
  related: SingleQuestionResource[],
  subject: NameValueResource,
}

export interface QuestionPaginatorResource {
  id: number,
  question: string,
  kimLine: number,
  section: NameValueResource,
  theme: NameValueResource,
  isPublished: NameValueResource,
  relatedUuid: string|null,
}

export interface QuestionPublishRequest {
  isPublished: boolean,
}

export interface QuestionPublishResource {
  isPublished: boolean,
}

export interface FavoriteResource {
  isFavorite: boolean,
}

export interface QuestionPaginatorRequest {
  sectionId?: number|null,
  subjectId: number,
  perPage: number,
  page: number,
  kimLine?: number|null,
  themeId?: number|null,
  search?: string|null,
  status?: QuestionStatus,
}

export interface PaginatorMeta {
  currentPage: number,
  from: number|null,
  lastPage: number,
  perPage: number,
  to: number|null,
  total: number,
}

export enum QuestionStatus {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

// Base paginator object
export interface BasePaginator {
  meta: PaginatorMeta,
}

export interface QuestionPaginator {
  meta: PaginatorMeta,
  data: QuestionPaginatorResource[],
}

export interface TrainerPaginatorRequest {
  subjectId: number,
  perPage: number,
  page: number,
  sortOrder: SortOrder,
  sectionIds?: number[]|null,
  kimLine?: number|null,
  themeIds?: number[]|null,
  search?: string|null,
  isComplete?: boolean|null,
  isFavorite?: boolean|null, // Принимает только 1
}

export interface SectionsGetParams {
  subjectId: number,
  title?: string,
}

export interface SourcesGetParams {
  subjectId: number,
  title?: string,
}

export interface ThemesGetParams {
  sectionIds: number[],
  title?: string,
}

// Решение вопроса
export const DecidePostRequest = (body: DecideRequest, options?: any) => request<AnsweredQuestionResource>('/decide', { body, method: 'POST', ...options })

// Получение списка линий ким для предмета
export const KimSubjectIdGetRequest = (subjectId: number, options?: any) => request<number[]>(`/kim/${subjectId}`, { method: 'GET', ...options })

// Пагинатор тренажера.
export const PaginatorGetRequest = (body: TrainerPaginatorRequest, options?: any) => request<TrainerPaginator>('/paginator', { body, method: 'GET', ...options })

// Справочник типов вопросов
export const QuestionTypesGetRequest = (options?: any) => request<NameValueResource[]>('/question/types', { method: 'GET', ...options })

// Пагинатор вопросов
export const QuestionsPaginatorGetRequest = (params: QuestionPaginatorRequest, options?: any) => request<QuestionPaginator>('/questions/paginator', { method: 'GET', params, ...options })

// Сохранение вопроса
export const QuestionsStorePostRequest = (body: QuestionStoreRequest, options?: any) => request<SingleQuestionResource>('/questions/store', { body, method: 'POST', ...options })

// Просмотр вопроса
export const QuestionsQuestionIdGetRequest = (questionId: number, options?: any) => request<SingleQuestionResource>(`/questions/${questionId}`, { method: 'GET', ...options })

// Удаление вопроса
export const QuestionsQuestionIdDeleteRequest = (questionId: number, options?: any) => request<any>(`/questions/${questionId}`, { method: 'DELETE', ...options })

// Публикация вопроса
export const QuestionsQuestionIdPublishPostRequest = (questionId: number, body: QuestionPublishRequest, options?: any) => request<QuestionPublishResource>(`/questions/${questionId}/publish`, { body, method: 'POST', ...options })

// Справочник разделов
export const SectionsGetRequest = (params: SectionsGetParams, options?: any) => request<SectionResource[]>('/sections', { method: 'GET', params, ...options })

// Просмотр вопроса
export const ShowQuestionIdGetRequest = (questionId: number, options?: any) => request<AnsweredQuestionResource>(`/show/${questionId}`, { method: 'GET', ...options })

// Справочник разделов
export const SourcesGetRequest = (params: SourcesGetParams, options?: any) => request<SourceResource[]>('/sources', { method: 'GET', params, ...options })

// Справочник статусов вопросов.
export const StatusesGetRequest = (options?: any) => request<NameValueResource[]>('/statuses', { method: 'GET', ...options })

// Справочник предметов
export const SubjectsGetRequest = (options?: any) => request<SubjectResource[]>('/subjects', { method: 'GET', ...options })

// Справочник тем
export const ThemesGetRequest = (params: ThemesGetParams, options?: any) => request<ThemeResource[]>('/themes', { method: 'GET', params, ...options })

// Загрузка файла
export const UploadPostRequest = (body: UploadRequest, options?: any) => request<MediaResource>('/upload', { body, method: 'POST', ...options })

// Загрузка файла по чанкам
export const UploadChunkPostRequest = (body: ChunkRequest, options?: any) => request<MediaChunkResource>('/upload/chunk', { body, method: 'POST', ...options })

// Пометить вопрос в избранные
export const QuestionIdFavoritePostRequest = (questionId: number, body: FavoriteRequest, options?: any) => request<FavoriteResource>(`/${questionId}/favorite`, { body, method: 'POST', ...options })

