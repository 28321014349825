import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

// Права на управление разделом "Тренажер"
export default function manageTraining ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.TRAINER_METHODIST, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
