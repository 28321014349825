import auth from '@/router/_middleware/auth'
import isManager from '@/router/_middleware/isManager'
import { viewCalendar, viewDashboard } from '@/router/_middleware/manageEducation'

import bankRoutes from './bank'
import commerceRoutes from './commerce'
import controlRoutes from './control'
import educationRoutes from './education'
import supportRoutes from './support'
import trainingRoutes from './training'
import usersRoutes from './users'

export default [
  {
    children: [
      ...bankRoutes,
      ...commerceRoutes,
      ...controlRoutes,
      ...educationRoutes,
      ...supportRoutes,
      ...trainingRoutes,
      ...usersRoutes,
      {
        component: () => import('@/views/manager/calendar/Calendar.vue'),
        meta: { middleware: [auth, isManager, viewCalendar] },
        name: 'manager.calendar',
        path: '/manager/calendar',
      },
      {
        component: () => import('@/views/manager/ManagerDashboard.vue'),
        meta: { middleware: [auth, isManager, viewDashboard] },
        name: 'manager.dashboard',
        path: '/manager/dashboard',
      },
    ],
    component: () => import('@/views/Manager.vue'),
    meta: { middleware: [auth, isManager] },
    name: 'manager',
    path: '/manager',
  },
]
